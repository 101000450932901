import React from "react";
import PropTypes from "prop-types";
import "./IconTextButton.css";

const IconTextButton = ({ svgComponent, label, onClick }) => {
	const Icon = svgComponent;

	return (
		<React.Fragment>
			<div className="button" onClick={onClick}>
				<div className="svg">
					<Icon />
				</div>
				<p className="labelText">{label}</p>
			</div>
		</React.Fragment>
	);
};

IconTextButton.propTypes = {
	/**
	 * Optional click handler
	 */
	onClick: PropTypes.func.isRequired,

	/**
	 * Import the SVG icon as a ReactComponent and pass it here. See .stories file for example.
	 */
	svgComponent: PropTypes.element.isRequired,

	/**
	 * label text for the button
	 */
	label: PropTypes.string.isRequired,
};

IconTextButton.defaultProps = {};

export default IconTextButton;
