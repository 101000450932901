import React from "react";
import ReactDOM from "react-dom";
import App from './App';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import StewardCallback from './components/StewardCallback'

import "./index.css";

const { REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENT_ID, REACT_APP_AUTH0_AUDIENCE } = process.env;

ReactDOM.render(
  <Router>
    <Switch>
      <Route path="/steward" exact component={StewardCallback}/>
      <Route path="/*">
        <Auth0Provider
          domain={REACT_APP_AUTH0_DOMAIN}
          clientId={REACT_APP_AUTH0_CLIENT_ID}
          redirectUri={window.location.origin}
          audience={REACT_APP_AUTH0_AUDIENCE}
        >
          <App />
        </Auth0Provider>
      </Route>
    </Switch>
  </Router>,
  document.getElementById("root")
);