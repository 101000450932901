import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import "./ShareAccess.css";
import IconTextButton from "../../Controls/IconTextButton/IconTextButton";
import { ListItem } from "../../Lists/ListItem/ListItem";
import Input from "../../../components/Input";
import { ReactComponent as BIcon } from "../../../img/back.svg";
import { ReactComponent as SIcon } from "../../../img/Send.svg";
import NavBar from "../../../components/NavBar";

const ShareAccess = (props) => {
	let history = useHistory();

	function removeAccess(e) {}
	return (
		<React.Fragment>
			<div class="main">
				<NavBar showAvatar showBurger />
				<div class="header">
					<div class="icon">
						<BIcon
							onClick={() => {
								history.goBack();
							}}
						/>
					</div>
					<p class="subtitle">Share</p>
				</div>

				<div class="share-form">
					<Input placeholder="Enter an ID" />
					<IconTextButton
						onClick={() => {
							alert("clicked send");
						}}
						svgComponent={SIcon}
						label="Send"
					/>
				</div>
			</div>
		</React.Fragment>
	);
};

ShareAccess.propTypes = {};

ShareAccess.defaultProps = {};

export default ShareAccess;
