import React from "react";
import PropTypes from "prop-types";
import "./RecordItem.css";
import { ReactComponent as FIcon } from "../../../img/forward.svg";

const formatDate = (date) => {
	try {
		const options = {
			weekday: "short",
			year: "numeric",
			month: "long",
			day: "numeric",
		};
		return date.toLocaleDateString("en-US", options);
		// return date.toLocaleDateString("en-US");
	} catch (err) {
		return "unknown";
	}
};

export const RecordItem = (props) => {
	return (
		<React.Fragment>
			<div className="items">
				<div className="avatar">
					<img
						src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1100&q=80"
						alt=""
					/>
				</div>
				<div className="content">
					<p className="title">{props.title}</p>
					<p className="updated">
						Updated: {formatDate(props.lastUpdated)}
					</p>
				</div>
				<div className="button">
					<FIcon
						onClick={() => {
							alert("clicked");
						}}
					/>
				</div>
			</div>
			<hr />
		</React.Fragment>
	);
};

RecordItem.propTypes = {
	/**
	 * The title of the document
	 */
	title: PropTypes.string.isRequired,
	/**
	 * The date the document was last modified
	 */
	lastUpdated: PropTypes.object.isRequired,
};

RecordItem.defaultProps = {
	title: "title",
	lastUpdated: Date("August 19, 1975 23:15:30"),
};
