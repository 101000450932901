import React from "react";
import PropTypes from "prop-types";
import "./RecordList.css";
import NavBar from "../../../components/NavBar/index";
import { RecordItem } from "../../Lists/RecordItem/RecordItem";

const RecordList = (props) => {
	return (
		<React.Fragment>
			<div className="main">
				<NavBar showAvatar showBurger />
				<h1 class="pageTitle">Your Records</h1>
				<div className="recordItems">
					<RecordItem
						title="Frontida Health Records"
						lastUpdated={new Date("April 1, 1973")}
					/>
					<RecordItem
						title="Crisis Managment Association"
						lastUpdated={new Date("December 5, 1997")}
					/>
					<RecordItem
						title="Italian Hospital"
						lastUpdated={new Date("5/9/1990")}
					/>
				</div>
			</div>
		</React.Fragment>
	);
};

RecordList.propTypes = {};

RecordList.defaultProps = {};

export default RecordList;
