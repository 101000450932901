import React, { Component } from 'react';
import LoadingSpinner from '../components/LoadingSpinner';
import SignUpForm from '../components/SignUpForm'


export default function SignUpPage(props) {
  return (
    <div>
      <SignUpForm/>
    </div>
  );
};