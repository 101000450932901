import React, { Component } from 'react';
import { Button } from 'react-bootstrap'
import Oidc from 'oidc-client'
import jwt_decode from "jwt-decode"
import { Redirect } from 'react-router-dom'

const AUTH_SERVER = 'https://auth.oasiscloud.io';

const { REACT_APP_URL } = process.env;

const config = {
    authority: AUTH_SERVER,
    metadata: {
        issuer: AUTH_SERVER,
        authorization_endpoint: AUTH_SERVER + '/oauth/authorize',
        jwks_uri: AUTH_SERVER + '/oauth/keys',
        token_endpoint: AUTH_SERVER + '/oauth/token',
    },
    client_id: '95af4db0-8d75-4aab-8fbd-c765b87ebdd4',
    redirect_uri: REACT_APP_URL + '/steward',
    response_type: 'code',
    scope: 'openid',
    filterProtocolClaims: false,
    loadUserInfo: false,
    extraQueryParams: {
        audience: 'https://api.oasislabs.com/parcel',
    },
};

Oidc.Log.logger = console;
Oidc.Log.level = Oidc.Log.DEBUG;
const oidcClient = new Oidc.OidcClient(config);


class StewardCallback extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            address: null,
            redirect: false
        };
    }
    processCallback = async () => {
        try {
            console.log('HREF: ' + window.location.href);
            const response = await oidcClient.processSigninResponse(window.location.href);
            console.log('RESPONSE: ' + response);
            const IDToken = response.id_token;
            const decoded = jwt_decode(IDToken);
            const address = decoded.sub;

            console.log('Address = ' + address)
            this.setState({
                address: address,
                redirect: true
            })
            // TODO: Figure out how to pass the address to the signup form

        } catch (error) {
            console.log('CALLBACK ERROR: ' + error);
            this.setState({
                error: error,
                redirect: true
            })
        }
    }

    componentDidMount() {
        this.processCallback();
    }

    render() {
        return (
            <div>
                This is the Steward Callback
                { this.state.redirect && 
                <Redirect to={{
                    pathname: "/signUp/",
                    state: { address: this.state.address }
                }}/>}
            </div>
        );
    }
}

export default StewardCallback;