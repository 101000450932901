import { useAuth0 } from "@auth0/auth0-react";
import { ListGroup } from "react-bootstrap";
import LoadingSpinner from "../LoadingSpinner";
import React, { useState, useEffect } from "react";
import { DataView } from "../../stories/DataView/DataView";
const { REACT_APP_API_URL } = process.env;

export default function DocList({ docList }) {
	const [selectedDoc, setSelectedDoc] = useState(null);
	const [loadingDoc, setLoadingDoc] = useState(false);
	const { getAccessTokenSilently } = useAuth0();
	function handleItemClick(e) {
		e.preventDefault();
		setLoadingDoc(true);
		const addr = e.currentTarget.value;
		getAccessTokenSilently().then((token) => {
			fetch(`${REACT_APP_API_URL}/api/patient/download/` + addr, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
			})
				.then((res) => res.json())
				.then((res) => {
					setSelectedDoc(res.data);
					setLoadingDoc(false);
				})
				.catch((err) => {
					console.log(err);
					setLoadingDoc(false);
				});
		});
	}

	return (
		<div className="row">
			<div className="col-4"></div>

			<div className="col-4">
				<div className="row">
					<div className="col shadow p-3 mb-5 bg-white rounded ">
						<ListGroup>
							{docList?.map(({ address, title }) => (
								<ListGroup.Item
									key={address}
									value={address}
									action
									onClick={handleItemClick}
								>
									{" "}
									<b>DOCUMENT TITLE:</b> {title}
								</ListGroup.Item>
							))}
						</ListGroup>
					</div>
				</div>
			</div>

			<div className="col-4"></div>

			<div className="col-12">
				{loadingDoc && <LoadingSpinner></LoadingSpinner>}
				{selectedDoc && <DataView data={selectedDoc} />}
			</div>
		</div>
	);
}
