import React, { useState } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import burger from "./img/Burger.png";
import avatar from "./img/Avatar.png";
import backIcon from "./img/Back.png";

import Logout from "../Logout";
import "./NavBar.css";

function NavBar(props) {
	const [dropDownOpen, setDropDownOpen] = useState(false);

	return (
		<div
			onClick={(e) => {
				setDropDownOpen(false);
			}}
		>
			<div
				className="m-3 nav-container"
				onClick={(e) => {
					e.stopPropagation();
				}}
			>
				{props.showBack && (
					<div className="back-container">
						<img src={backIcon}></img>
					</div>
				)}
				{props.showBurger && (
					<div>
						<div
							className="burger-container"
							onClick={(e) => {
								setDropDownOpen(!dropDownOpen);
							}}
						>
							<img src={burger}></img>
						</div>
						<div
							className={
								dropDownOpen || props.dropDownAlwaysOpen
									? "dropdown-fadeIn"
									: "dropdown-fadeOut"
							}
						>
							<div className="dropdown-content p-3">
								<Nav.Link to="/">Home</Nav.Link>
								<Nav.Link to="/">Home</Nav.Link>
								<Logout />
							</div>
						</div>
					</div>
				)}
				{props.showAvatar && (
					<div className="avatar-container">
						<img src={props.avatar ? props.avatar : avatar}></img>
					</div>
				)}
			</div>
			{props.contentBox && (
				<div className="bg-primary content-box">Content Section</div>
			)}
			{props.children}
		</div>
	);
}

export default NavBar;
