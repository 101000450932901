import React from "react";
import PropTypes from "prop-types";
import IconTextButton from "../../Controls/IconTextButton/IconTextButton";
import "./ListItem.css";
import { useHistory } from "react-router-dom";
import { ReactComponent as XIcon } from "../../../img/x.svg";

export const ListItem = (props) => {
	const history = useHistory();
	// Determine which grid to use
	let gridClass = "";
	if (props.text && props.icon && props.image) {
		gridClass = "image-text-icon-grid";
	} else if (props.text && props.iconUrl) {
		gridClass = "text-icon-grid";
	} else if (props.text && props.imageUrl) {
		gridClass = "image-text-grid";
	} else {
		gridClass = "text-grid";
	}
	return (
		<React.Fragment>
			<div className={gridClass}>
				<div className="image-area">
					{/* TODO: Add ability to have image */}
				</div>
				<div className="text-area">
					{props.text && <p className="text">{props.text}</p>}
					{props.subtext && (
						<p className="subtext">{props.subtext}</p>
					)}
				</div>
				{props.iconUrl && (
					<div className="icon-area">
						<IconTextButton
							label=""
							svgComponent={XIcon}
							onClick={() => {
								alert("clicked remove access");
							}}
						/>
					</div>
				)}
			</div>
			<hr />
		</React.Fragment>
	);
};

ListItem.propTypes = {
	/**
	 * What is the name of the key?
	 */
	// keyName: PropTypes.string.isRequired,
	/**
	 * What is the type of the value this key points to?
	 */
	// type: PropTypes.oneOf(["string", "number", "image"]).isRequired,
	/**
	 * The value to be rendered
	 */
	// value: PropTypes.any,
};

ListItem.defaultProps = {};
