import React, { Component } from "react";
import Login from "./components/Login";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	useLocation,
} from "react-router-dom";

import { useAuth0, isAuthenticated } from "@auth0/auth0-react";
import NavBar from "./components/NavBar";
import PrivateRoute from "./routes/private-route";
import "bootstrap/dist/css/bootstrap.min.css";
import LoadingSpinner from "./components/LoadingSpinner";

// Import pages
import Home from "./pages/Home";
import SignUpPage from "./pages/SignUpPage";

import AccessHistory from "./stories/Pages/AccessHistory/index";
import DataDetails from "./stories/Pages/DataDetails/index";
import DataOverview from "./stories/Pages/DataOverview/index";
import ManageAccess from "./stories/Pages/ManageAccess/index";
import RecordList from "./stories/Pages/RecordList/index";
import ShareAccess from "./stories/Pages/ShareAccess/index";

function App() {
	const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
	const location = useLocation();

	return (
		<div id="app" className="d-flex flex-column h-100">
			{isLoading && <LoadingSpinner />}
			{!isLoading && !isAuthenticated && loginWithRedirect()}
			{!isLoading && isAuthenticated && (
				<React.Fragment>
					{/* <NavBar showBurger showAvatar></NavBar> */}
					<Switch>
						<PrivateRoute path="/" exact component={Home} />
						<PrivateRoute
							path="/signUp"
							exact
							component={SignUpPage}
						/>

						{/* These Private routes are for the MVP we will show for deliverable 6 */}
						<PrivateRoute
							path="/v1/accessHistory"
							exact
							component={AccessHistory}
						/>

						<PrivateRoute
							path="/v1/dataDetails"
							exact
							component={DataDetails}
						/>

						<PrivateRoute
							path="/v1/dataOverview"
							exact
							component={DataOverview}
						/>

						<PrivateRoute
							path="/v1/manageAccess"
							exact
							component={ManageAccess}
						/>

						<PrivateRoute
							path="/v1/recordList"
							exact
							component={RecordList}
						/>

						<PrivateRoute
							path="/v1/shareAccess"
							exact
							component={ShareAccess}
						/>
						<Route path="*">
							<div
								style={{
									textAlign: "center",
									maxWidth: "300px",
									margin: "30vh auto 0",
								}}
							>
								<h3>
									Sorry, we couldn't find anything at
									<code> {location.pathname}</code>
								</h3>
							</div>
						</Route>
					</Switch>
				</React.Fragment>
			)}
		</div>
	);
}

export default App;
