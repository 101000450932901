import React from "react";
import PropTypes from "prop-types";
import "./DataOverview.css";
import IconTextButton from "../../Controls/IconTextButton/IconTextButton";
import { KeyValueItem } from "../../Lists/KeyValueItem/KeyValueItem";
import NavBar from "../../../components/NavBar/index";

import { ReactComponent as AHIcon } from "../../../img/2 User.svg";
import { ReactComponent as MAIcon } from "../../../img/Setting.svg";
import { ReactComponent as SIcon } from "../../../img/Send.svg";

const DataOverview = (props) => {
	return (
		<React.Fragment>
			<div className="main">
				<NavBar showAvatar showBurger />
				<h1 className="pageTitle">
					Frontida Health <br /> Records
				</h1>

				<div className="buttonRow">
					<IconTextButton
						label="Access History"
						svgComponent={AHIcon}
						onClick={() => {}}
					/>
					<IconTextButton
						label="Manage Access"
						svgComponent={MAIcon}
						onClick={() => {}}
					/>
					<IconTextButton
						label="Share"
						svgComponent={SIcon}
						onClick={() => {}}
					/>
				</div>
				<h6 className="subtitle">Data</h6>
				<div className="keyValueItems">
					<KeyValueItem
						keyName="Secret Message"
						type="string"
						value="Heres a super secret message Muahahahah"
					/>
					<KeyValueItem
						keyName="Bitcoin Owned"
						type="number"
						value={12.34}
					/>
					<KeyValueItem
						keyName="Secret Picture"
						type="image"
						value="this will be an image one day"
					/>
				</div>
			</div>
		</React.Fragment>
	);
};

DataOverview.propTypes = {};

DataOverview.defaultProps = {};

export default DataOverview;
