import React, { Component, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { Button, Form, Card } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';

import StewardButton from '../StewardButton'

import './SignUpForm.css'

const { REACT_APP_API_URL } = process.env;

export default function SignUpForm(props) {
    let location = useLocation();
    const address = location.state?.address
    const [name, setName] = useState(null)
    const [redirect, setRedirect] = useState(null)

    const { getAccessTokenSilently } = useAuth0();

    // return (<SignUpFormComp address={location.state?.address} />)
    async function handleSubmit(e) {
        e.preventDefault()
        getAccessTokenSilently().then(token => {
            fetch(REACT_APP_API_URL + "/api/user/signup", {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    user: {
                        name: name,
                        oasisId: address
                    }
                })
            }).then(res => {
                if (res.status == 200)
                    setRedirect("/")
                else
                    setRedirect("/error")
            }).catch(e => {
                console.log(e)
                setRedirect("/error")
            })
        })
    }
    return (
        <div className="container align-middle">
            <Card style={{ width: '30rem' }} className="shadow p-3 mb-5 bg-white rounded">
                <Card.Body>
                    <Card.Title>Sign Up Form</Card.Title>
                    <Form onSubmit={handleSubmit}>
                        {!address &&
                            <div>
                                <Card.Text>First, let's link your Oasis account</Card.Text>
                                <StewardButton />
                            </div>
                        }
                        {address &&
                            <div>
                                <Card.Text>Great, your address is: {address}</Card.Text>
                                <Card.Text>Name</Card.Text>
                                <Form.Control type="text" placeholder="Enter your name" onChange={e => setName(e.target.value)}></Form.Control>
                            </div>
                        }
                        {address && name &&
                            <div>
                                <Button className="mt-3" variant="primary" type="submit">
                                    Sign Up
                                </Button>
                            </div>
                        }
                    </Form>
                </Card.Body>
            </Card>
            { redirect && <Redirect to={redirect} />}
        </div>
    )
}