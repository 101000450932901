import React from "react";
import PropTypes from "prop-types";
import IconTextButton from "../../Controls/IconTextButton/IconTextButton";
import "./DataDetails.css";
import NavBar from "../../../components/NavBar";
import { KeyValueItem } from "../../Lists/KeyValueItem/KeyValueItem";

import { ReactComponent as ABIcon } from "../../../img/ArrowBack.svg";
import { useHistory } from "react-router-dom";

const DataDetails = (props) => {
	const history = useHistory();

	return (
		<React.Fragment>
			<NavBar showAvatar showBurger />
			<div className="main">
				<div className="backButton">
					<IconTextButton
						label="overview"
						svgComponent={ABIcon}
						onClick={() => {
							history.goBack();
						}}
					/>
				</div>
				<h1 class="pageTitle">Details</h1>
				<div className="keyValueItems">
					<KeyValueItem
						keyName="Secret Message"
						type="string"
						value="Heres a super secret message Muahahahah"
					/>
					<KeyValueItem
						keyName="Bitcoin Owned"
						type="number"
						value={12.34}
					/>
					<KeyValueItem
						keyName="Secret Picture"
						type="image"
						value="this will be an image one day"
					/>
				</div>
			</div>
		</React.Fragment>
	);
};

DataDetails.propTypes = {};

DataDetails.defaultProps = {};

export default DataDetails;
