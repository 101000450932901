import React from "react";
import PropTypes from "prop-types";
import "./DataView.css";

export const DataView = ({ data }) => {
	if (!data) {
		return <h3>No Data Found.</h3>;
	}
	return (
		<React.Fragment>
			<div className="row align-items-center">
				<div className="col-9 m-auto shadow p-3 mb-5 bg-white rounded border border">
					<h4>
						First Name:
						<span className="font-italic"> {data.firstName}</span>
					</h4>
					<h4>
						Last Name:
						<span className="font-italic"> {data.lastName}</span>
					</h4>
					<h4>
						Status:
						<span className="font-italic"> {data.status}</span>
					</h4>
				</div>
			</div>
		</React.Fragment>
	);
};

DataView.propTypes = {
	/**
	 * This is the data response object obtained from the oasis api
	 */
	data: PropTypes.object,
};

DataView.defaultProps = {
	data: null,
};
