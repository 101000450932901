import React from "react";
import PropTypes from "prop-types";
import "./KeyValueItem.css";

export const KeyValueItem = (props) => {
	return (
		<React.Fragment>
			<div className="KVItemBox">
				<p className="keyName">{props.keyName}</p>
				<p className="value">{props.value}</p>
			</div>
			<hr />
		</React.Fragment>
	);
};

KeyValueItem.propTypes = {
	/**
	 * What is the name of the key?
	 */
	keyName: PropTypes.string.isRequired,

	/**
	 * What is the type of the value this key points to?
	 */
	type: PropTypes.oneOf(["string", "number", "image"]).isRequired,
	/**
	 * The value to be rendered
	 */
	value: PropTypes.any,
};

KeyValueItem.defaultProps = {};
