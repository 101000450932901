import React from 'react';
import SemipolarSpinner from '@bit/bondz.react-epic-spinners.semipolar-spinner';

import './LoadingSpinner.css'

export default function () {
	return (
		<div className="container">
			<div className="middle">
				<SemipolarSpinner
					color='#175798'
				/>
			</div>
		</div>
	)
}