import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import "./ManageAccess.css";
import IconTextButton from "../../Controls/IconTextButton/IconTextButton";
import { ListItem } from "../../Lists/ListItem/ListItem";

import NavBar from "../../../components/NavBar";

import { ReactComponent as ABIcon } from "../../../img/ArrowBack.svg";

const ManageAccess = (props) => {
	let history = useHistory();

	function removeAccess(e) {}
	return (
		<React.Fragment>
			<div class="main">
				<NavBar showAvatar showBurger />
				<div class="header">
					<div class="icon">
						<IconTextButton
							label=""
							svgComponent={ABIcon}
							onClick={() => {
								history.goBack();
							}}
						/>
					</div>
					<p class="subtitle">Manage Access</p>
				</div>

				<div class="listItems">
					<ListItem
						text="Billy Bob"
						iconUrl="./x.svg"
						onIconClick={(e) => removeAccess(e)}
					/>
					<ListItem
						text="Charlie Charleston"
						iconUrl="./x.svg"
						onIconClick={(e) => removeAccess(e)}
					/>
					<ListItem
						text="David Doe"
						iconUrl="./x.svg"
						onIconClick={(e) => removeAccess(e)}
					/>
				</div>
			</div>
		</React.Fragment>
	);
};

ManageAccess.propTypes = {};

ManageAccess.defaultProps = {};

export default ManageAccess;
