import React, { useState } from "react";
// import { Nav, Navbar, NavDropdown } from "react-bootstrap"

import Logout from "../Logout";
import "./Input.css";

function Input(props) {
	return (
		<div className={"wrapper " + (props.shadowBox ? "shadow " : "")}>
			<div className="input-data">
				<input type="text" placeholder={props.placeholder} />
				<div className="underline" />
				<label>
					{props.placeholder ? props.placeholder : "Placeholder"}
				</label>
			</div>
		</div>
	);
}

export default Input;
