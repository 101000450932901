import { Button } from 'react-bootstrap'
import Oidc from 'oidc-client'
import { Redirect } from 'react-router-dom'

const AUTH_SERVER = 'https://auth.oasiscloud.io';

const { REACT_APP_URL } = process.env;

const config = {
    authority: AUTH_SERVER,
    metadata: {
        issuer: AUTH_SERVER,
        authorization_endpoint: AUTH_SERVER + '/oauth/authorize',
        jwks_uri: AUTH_SERVER + '/oauth/keys',
        token_endpoint: AUTH_SERVER + '/oauth/token',
    },
    client_id: '95af4db0-8d75-4aab-8fbd-c765b87ebdd4',
    redirect_uri: REACT_APP_URL + '/steward',
    response_type: 'code',
    scope: 'openid',
    filterProtocolClaims: false,
    loadUserInfo: false,
    extraQueryParams: {
        audience: 'https://api.oasislabs.com/parcel',
    },
};

Oidc.Log.logger = console;
Oidc.Log.level = Oidc.Log.DEBUG;
const oidcClient = new Oidc.OidcClient(config);

const obtainToken = async () => {
    const request = await oidcClient.createSigninRequest();
    console.log('REDIRECT: ' + request.url)
    window.location = request.url
}

export default function StewardButton(props) {
    return(
        <Button onClick={obtainToken}>
            Connect Steward ID
        </Button>
    )
}