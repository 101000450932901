import React, { Component, useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import LoadingSpinner from '../components/LoadingSpinner';
import { useAuth0 } from '@auth0/auth0-react';
import { ListGroup } from 'react-bootstrap'
import DocList from '../components/DocList'
const { REACT_APP_API_URL } = process.env;

export default function Home() {
  const [isLoaded, setIsLoaded] = useState(false)
  const [redirectSignUp, setRedirectSignUp] = useState(false)
  const [docList, setDocList] = useState(null)
  const [loadedList, setLoadedList] = useState(false)
  const [user, setUserState] = useState(null)
  const [token, setToken] = useState(null)

  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    getAccessTokenSilently()
      .then(tokenPayload => {
        setToken(tokenPayload)
        console.log("TOKEN SET")
        return fetch(`${REACT_APP_API_URL}/api/user`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${tokenPayload}`,
            'Content-Type': 'application/json'
          }
        })
          .then(userPayload => {
            if (userPayload.status == 404) {
              setRedirectSignUp(true)
              setIsLoaded(true)
              return null
            }
            return userPayload.json()
          })
          .then(userJSON => {

            // Error check if userJSON exists
            if (!userJSON)
              return

            // Set user state to HTTP payload
            setUserState(userJSON)

            // Return promise containing documents list for user token
            return fetch(`${REACT_APP_API_URL}/api/document/getDocuments`, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${tokenPayload}`,
                'Content-Type': 'application/json'
              }
            })
          })
          .then(documents => documents.json())
          .then(documentsJSON => {
            setDocList(documentsJSON.documents)
            console.log("DOCUMENTS JSON: ", JSON.stringify(documentsJSON, null, 2))
            // Show that data is loaded
            setIsLoaded(true)
          })
          .catch(e => {
            console.log(e)
          })
      })

  }, [])

  useEffect(() => console.log("TOKEN: ", token), [token])
  useEffect(() => console.log("DOCLIST: ", docList), [docList])



  return (
    <>
      { !isLoaded && <LoadingSpinner />}
      { redirectSignUp && <Redirect to="/signup" />}
      { isLoaded &&
        <div>
          <h1 className="text-center">Hello {user.user.name}!</h1>
          {!docList ? <h5 className="text-center">No Documents To Show Right Now</h5> : <DocList docList={docList} />}
        </div>
      }
    </>
  )
}