import React from "react";
import { useHistory } from "react-router-dom";
import "./AccessHistory.css";
import IconTextButton from "../../Controls/IconTextButton/IconTextButton";
import { ListItem } from "../../Lists/ListItem/ListItem";

import NavBar from "../../../components/NavBar";

import { ReactComponent as ABIcon } from "../../../img/ArrowBack.svg";

const AccessHistory = (props) => {
	let history = useHistory();
	return (
		<React.Fragment>
			<div className="main">
				<NavBar showAvatar showBurger />
				<div className="header">
					<div className="icon">
						<IconTextButton
							label=""
							svgComponent={ABIcon}
							onClick={() => {
								history.goBack();
							}}
						/>
					</div>
					<p className="subtitle">Access History</p>
				</div>

				<div className="listItems">
					<ListItem
						text="Billy Bob"
						type="string"
						subtext="Updated yesterday"
					/>
					<ListItem
						text="Billy Bob"
						type="string"
						subtext="Updated yesterday"
					/>
					<ListItem
						text="Billy Bob"
						type="string"
						subtext="Updated yesterday"
					/>
				</div>
			</div>
		</React.Fragment>
	);
};

AccessHistory.propTypes = {};

AccessHistory.defaultProps = {};

export default AccessHistory;
