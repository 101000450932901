import React from "react";
import { Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import LoadingSpinner from "../components/LoadingSpinner";

const PrivateRoute = ({ component, ...args }) => (
	/*
  From Steve
  I removed the following code because it seems that in app.js we already redirect 
  the user to a login screen if they aren't authenticated. 
  This effectivley makes the entire app protected, so private routes aren't really needed...
  Keeping this file in case we decide to add public routes in the future.
  */

	// <Route
	//   component={withAuthenticationRequired(component, {
	//     onRedirecting: () => <LoadingSpinner/>,
	//   })}
	//   {...args}
	// />

	<Route component={component} {...args} />
);

export default PrivateRoute;
